import {JsonObject, JsonProperty} from "json2typescript";
import Document from "@/models/Document";
import Auditing from "@/models/Auditing";

@JsonObject("Promo")
export default class Promo extends Auditing {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("document", Document, true)
    document?: Document = undefined

}
