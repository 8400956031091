import {Vue} from "vue-property-decorator";
import Promo from "@/models/Promo";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";

export default class PromoService {

    static postPromo(component: Vue, promo: Promo, file: File | null = null) {
        let formData: FormData = new FormData()
        if (promo.name) formData.set("name", promo.name!)
        if (file) formData.set("document", file)
        // @ts-ignore
        component.loading = true
        component.axios.post(ConstantTool.BASE_URL + "/api/promos",
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                let item: Promo = JsonTool.jsonConvert.deserializeObject(response.data, Promo)
                component.$router.push({path: "/promos/" + item.id})
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo crear la promoción"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static patchPromo(component: Vue, promoId: number, promo: Promo, file: File | null = null) {
        let formData: FormData = new FormData()
        if (promo.name) formData.set("name", promo.name!)
        if (file) formData.set("document", file)
        // @ts-ignore
        component.loading = true
        component.axios.patch(ConstantTool.BASE_URL + "/api/promos/" + promoId,
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            .then(response => component.refresh())
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo actualizar la promoción"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static getPromos(component: Vue, promos: Promo[]) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/promos", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let list = JsonTool.jsonConvert.deserializeArray(response.data, Promo)
                promos.splice(0, promos.length)
                list.forEach(p => promos.push(p))
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se han podido obtener las promociones"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static getPromo(component: Vue, promoId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/promos/" + promoId, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let entity = JsonTool.jsonConvert.deserializeObject(response.data, Promo)
                // @ts-ignore
                component.promo = entity
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener la promoción"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static async deletePromo(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            await component.axios.delete(ConstantTool.BASE_URL + "/api/promos/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Eliminada una promocion");
        } catch (e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("Error al eliminar la promocion")
        }
    }

}
